const menuHamburgerIcon = document.getElementById('menuHamburgerIcon');
const menuHamburgerLabel = document.getElementById('menuHamburgerLabel');
const menuHamburgerCheckbox = document.getElementById('menuHamburgerCheckbox');
const menuNav = document.getElementById('mainNavList');
menuHamburgerLabel.addEventListener('click', function(){
	menuHamburgerIcon.classList.toggle('fa-bars');
	menuHamburgerIcon.classList.toggle('fa-times');
	menuNav.classList.toggle('invisible');
});

document.getElementById('aboutLink').addEventListener('click', function() {
	menuNav.classList.toggle('invisible');
	menuHamburgerIcon.classList.toggle('fa-bars');
	menuHamburgerIcon.classList.toggle('fa-times');
});

document.getElementById('specialistLink').addEventListener('click', function() {
	menuNav.classList.toggle('invisible');
	menuHamburgerIcon.classList.toggle('fa-bars');
	menuHamburgerIcon.classList.toggle('fa-times');
});

document.getElementById('consultLink').addEventListener('click', function() {
	menuNav.classList.toggle('invisible');
	menuHamburgerIcon.classList.toggle('fa-bars');
	menuHamburgerIcon.classList.toggle('fa-times');
});
